import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";

/* eslint-disable import/max-dependencies */
import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import PageLayout from "../components/layouts/page-layout";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";
import Container from "../../packages/Container/src";
import { Grid, GridItem } from "../../packages/Grid/src";
import Div from "../../packages/Div/src"; // eslint-disable-next-line import/no-named-as-default

import Card from "../../packages/Card/src";
import RatioContainer from "../../packages/RatioContainer/src";
import Select from "../../packages/Select/src";
import Flex from "../../packages/Flex/src";
import { Heading } from "../../packages/Typography/src";
var Iframe = styled("iframe").withConfig({
  displayName: "patterns__Iframe",
  componentId: "cwy74u-0"
})(["width:500% !important;height:500% !important;-webkit-transform:scale(0.2);transform:scale(0.2);-webkit-transform-origin:0 0;transform-origin:0 0;position:absolute;"]);

var PatternsPage = function PatternsPage(_ref) {
  var data = _ref.data;
  // const current = data.allFile.find(s => s.name === slug)
  return React.createElement(PageLayout, null, React.createElement(SiteHeader, null), React.createElement(Div, {
    bg: "dark",
    color: "white",
    py: "2.5rem"
  }, React.createElement(Container, null, React.createElement(Heading, {
    my: "0"
  }, "Patterns"))), React.createElement(Div, {
    pt: "2.5rem",
    bg: "grey.50"
  }, React.createElement(Container, null, React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(Div, null, React.createElement(Select, {
    bg: "transparent",
    bgHover: "grey.100",
    options: [{
      value: 1,
      label: "All patterns"
    }, {
      value: 2,
      label: "Control"
    }, {
      value: 3,
      label: "Templates"
    }]
  })), React.createElement(Div, null, data.allSitePage.edges.length, " patterns")))), React.createElement(Div, {
    bg: "grey.50"
  }, React.createElement(Container, {
    pt: "2.5rem",
    pb: "5rem"
  }, React.createElement(Grid, {
    gridRowGap: "2.5rem"
  }, data.allSitePage.edges.map(function (_ref2) {
    var node = _ref2.node;
    return React.createElement(GridItem, {
      as: Link,
      to: node.path,
      key: node.id,
      gridColumn: ["span 12", "span 6", "span 4", "span 3"]
    }, React.createElement(Card, {
      raised: true,
      bg: "white",
      borderRadius: "md",
      interactive: true,
      overflow: "hidden"
    }, React.createElement(RatioContainer, {
      ratio: 2 / 3,
      bg: "grey.500",
      color: "grey.200",
      style: {
        display: "flex",
        zoom: 1,
        opacity: 0.75,
        filter: "grayscale(1)"
      }
    }, React.createElement(Div, {
      position: "relative",
      width: "100%",
      height: "100%"
    }, React.createElement(Div, {
      position: "absolute",
      width: "100%",
      height: "100%",
      style: {
        zIndex: 2,
        opacity: "100%"
      }
    }), React.createElement(Iframe, {
      src: "https://ds-kit.now.sh/patterns/" + node.path.replace("/pattern-viewer/", ""),
      style: {
        border: "none"
      }
    }))), React.createElement(Div, {
      p: "1.25rem",
      bg: "grey.50"
    }, React.createElement(Heading, {
      as: "h3",
      size: "lg",
      my: "0"
    }, node.path.split("/")[2].split("-").join(" ").split("").map(function (d, i) {
      return !i ? d.toUpperCase() : d;
    }).join("")))));
  })))), React.createElement(SiteFooter, null));
};

export var query = "4133450806";
export default PatternsPage;