import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable import/no-namespace */
import React from "react";
import { ThemeProvider } from "styled-components";
import Seo from "../Seo";
import GlobalStyle from "../GlobalStyle";
import * as theme from "../../../packages/theme/src";
import iconSpriteSheet from "../../images/datastory-icons.svg";
import flagSpriteSheet from "../../images/datastory-flags.svg";

var customTheme = _objectSpread({}, theme, {}, theme.typography, {
  spriteSheet: {
    icon: iconSpriteSheet,
    flag: flagSpriteSheet
  }
});

var PageLayout = function PageLayout(_ref) {
  var children = _ref.children;
  return React.createElement(ThemeProvider, {
    theme: customTheme
  }, React.createElement(React.Fragment, null, React.createElement(Seo, null), React.createElement(GlobalStyle, null), children));
};

export default PageLayout;